@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/Proxima_Nova_Regular.otf');
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'ProximaNovaBold';
  src: url('../fonts/Proxima_NovaBold.otf');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'ProximaNovaBold';
  src: url('../fonts/Proxima_Nova_Extrabold.otf');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'ProximaNovaSemibold';
  src: url('../fonts/Proxima_Nova_Semibold.otf');
  font-style: normal;
  font-display: block;
}


@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.ttf');
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('../fonts/Gilroy-ExtraBold.otf');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}