@import './assets/scss/fonts.scss';
@import './assets//scss/global.scss';

.mantine-DatePicker-dropdown {
  border-radius: 5px;
}

@media (max-width: 960px) {
  .grecaptcha-badge {
    display: none !important;
  }
}
.MuiDataGrid-columnHeader.maxima__table-header * {
  font-weight: 700;
  color: #004990;
  font-size: 16px;
}

.MuiDataGrid-columnHeaders {
  border-color: #004990 !important;
}

.MuiDataGrid-row {
  justify-content: space-between;
  cursor: pointer;
}

.MuiDataGrid-columnHeader {
  width: 100%;

  &:focus {
    outline: none !important;
  }
}

.MuiDataGrid-columnHeader.maxima__table-header {
  font-weight: 700;
  color: #004990;
  font-size: 16px;
  &:focus,
  &:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-columnSeparator--sideRight {
    display: none;
  }
}

.MuiDataGrid-iconSeparator {
  display: none;
}

.MuiDataGrid-cell.maxima__table-cell {
  border-color: #80a4c6 !important;
  &:focus {
    outline: none !important;
  }
}

.Toastify__toast {
  cursor: default !important;

  &.Toastify__toast--success {
    border: 1px solid #c9ebd7;
    background-color: #e9f7ef;
  }
  &.Toastify__toast--error {
    border: 1px solid #f7c6c8;
    background-color: #fce8e9;
  }

  & .Toastify__toast-body {
    color: #333;
    font-family: ProximaNova;
  }
  & .Toastify__toast-icon {
    margin-top: -1px;
  }
  & .Toastify__close-button {
    color: #767c79;
    opacity: 1;
  }
}
